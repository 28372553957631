<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ isRefund ? "Refund payment" : "Add payment" }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`orderChange.close`)"
            v-on:click="$emit('update:show', false)"
          ></button>
        </div>
        <div class="modal-body">
          <div
            class="text-start custom-input whitebg-input m-2"
            v-if="webshops.length > 0"
          >
            <div
              class="row align-items-center"
              v-for="order in modelValue"
              :key="order.id"
            >
              <div class="col-2 mb-3">
                <div class="bold-16">#{{ order.pretty_id }}</div>
                <div v-if="order.is_business">
                  {{ order.business_name }}
                </div>
                <div v-else>{{ order.first_name }} {{ order.last_name }}</div>
              </div>
              <div
                class="col-2 mb-3"
                :class="isRefund ? 'col-xxxl-2_5' : 'col-xxl-4'"
              >
                <div>
                  <span class="bold-12 text-gray">{{
                    $t("orderMultiplePayment.invoice")
                  }}</span>
                  {{ order.invoice.last_invoice_number }}
                </div>
                <BankDetailsToast
                  v-model="order.bankAccount"
                  v-if="order.bankAccount"
                />
              </div>
              <div
                class="mb-3 col-2"
                v-if="newPayment[order.id] && order.webshop_id"
              >
                <label class="form-label bold-12 text-gray">
                  {{ $t("order.payment") }}
                </label>
                <v-select
                  :options="
                    webshops
                      .find((e) => e.id === order.webshop_id)
                      .paymentMethods.filter(
                        (e) =>
                          (e.supported_currencies === null ||
                            e.supported_currencies.indexOf(
                              order.price.currency
                            ) > -1) &&
                          ((!isRefund &&
                            (e.delivery_method_id === null ||
                              e.delivery_method_id ===
                                order.delivery_method_id)) ||
                            (isRefund &&
                              (e.type === 1 || e.type === 3 || e.type === 4)))
                      )
                  "
                  v-model="newPayment[order.id].payment_method"
                  label="name"
                  :placeholder="$t(`orderMultiplePayment.selectPayment`)"
                  :reduce="(c) => c.id"
                  :clearable="false"
                  append-to-body
                />
              </div>
              <div class="mb-3 col-2" v-if="isRefund && newPayment[order.id]">
                <label class="form-label bold-12 text-gray">
                  {{ $t("orderMultiplePayment.automaticRefund") }}
                </label>
                <v-select
                  v-if="isRefund && refundMethods && refundMethods.length > 0"
                  :options="
                    refundMethods.filter((e) => {
                      return (
                        e.need_bank_details ||
                        e.payment_method_id ===
                          newPayment[order.id].payment_method
                      );
                    })
                  "
                  v-model="newPayment[order.id].refund_method"
                  label="name"
                  :placeholder="$t(`orderMultiplePayment.selectPayment`)"
                  :reduce="(c) => c.id"
                  append-to-body
                />
              </div>
              <div
                class="col-2 mb-3"
                v-if="newPayment[order.id]"
                :class="isRefund ? 'col-xxxl-1_5' : 'col-xxl-2'"
              >
                <div class="mt-4">
                  <span class="bold-12 text-gray">{{ $t("pos.payed") }}</span>
                  <EditOnClick
                    :model-value="order.payed_price"
                    type="price"
                    :editable="false"
                    :padding="false"
                  />
                </div>
                <div>
                  <span class="bold-12 text-gray">{{
                    $t("orderMultiplePayment.price")
                  }}</span>
                  <EditOnClick
                    :model-value="order.price"
                    type="price"
                    :editable="false"
                    :padding="false"
                  />
                </div>
              </div>
              <div v-if="newPayment[order.id]" class="col-2 mb-3">
                <label class="form-label bold-12 text-gray">
                  {{ $t("orderMultiplePayment.paying") }}
                </label>
                <div class="fix-end-input">
                  <input
                    type="number"
                    class="form-control"
                    v-model="newPayment[order.id].amount"
                    ref="imp"
                    step="0.01"
                  />
                  <span>{{ order.price.currency }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="addNewItems"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
          >
            {{ $t("orderChange.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
import { useStore } from "vuex";
import EditOnClick from "@/components/inputs/EditOnClick";
import BankDetailsToast from "@/components/lists/listItems/BankDetailsToast";
const bootstrap = require("bootstrap");

export default {
  name: "OrderMultiplePayment",
  props: {
    modelValue: Object,
    isRefund: Boolean,
  },
  emits: ["update:modelValue", "newItem"],
  data() {
    return {
      store: useStore(),
      myModal: undefined,
      success: false,
      loadAddBtn: false,
      webshops: [],
      newPayment: {},
      refundMethods: [],
    };
  },
  computed: {
    currencies() {
      return this.store.state.currencies;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    length() {
      return this.modelValue.length;
    },
  },
  watch: {
    isRefund() {
      this.calculate();
    },
    modelValue() {
      this.calculate();
    },
    webshops() {
      this.calculate();
    },
  },
  methods: {
    calculate() {
      if (this.isRefund && this.refundMethods.length === 0) {
        http.fetch("/payments/refunds").then((data) => {
          this.refundMethods = data;
          if (this.webshops.length > 0) {
            this.calculate2();
          }
        });
      } else if (this.webshops.length > 0) {
        this.calculate2();
      }
    },
    calculate2() {
      this.newPayment = {};
      for (const key in this.modelValue) {
        let paymentMethod =
          this.modelValue[key].payment_method_type === 1 || !this.isRefund
            ? this.modelValue[key].payment_method_id
            : this.webshops
                .find((e) => e.id === this.modelValue[key].webshop_id)
                .paymentMethods.filter((e) => e.type === 4)[0]?.id;
        this.newPayment[this.modelValue[key].id] = {
          payment_method: paymentMethod,
          amount: this.basicValue(
            this.modelValue[key].price?.amount,
            this.modelValue[key].payed_price?.amount
          ),
          refund_method:
            this.refundMethods.find(
              (e) => e.payment_method_id === paymentMethod
            )?.id ?? this.refundMethods.find((e) => e.need_bank_details)?.id,
        };
      }
    },
    basicValue(price, payed) {
      if (price === null) {
        price = 0;
      }
      if (payed === null) {
        payed = 0;
      }
      let e = 0;
      if (this.isRefund) {
        e = payed - price;
      } else {
        e = price - payed;
      }
      if (e < 0) {
        return 0;
      }
      return e;
    },
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      if (this.isRefund && this.refundMethods.length === 0) {
        http.fetch("/payments/refunds").then((data) => {
          this.refundMethods = data;
        });
      }
      if (this.webshops.length === 0) {
        http.fetch("/webshops").then((data) => {
          this.webshops = data;
        });
      }

      this.myModal.show();
    },

    addNewItems() {
      this.loadAddBtn = true;
      let change = [];
      for (const key in this.newPayment) {
        if (
          this.newPayment[key].amount > 0 &&
          this.newPayment[key].payment_method > 0
        ) {
          change.push({
            order_id: key,
            payment_method: this.newPayment[key].payment_method,
            refund_method: this.newPayment[key].refund_method ?? null,
            amount: this.isRefund
              ? this.newPayment[key].amount * -1
              : this.newPayment[key].amount,
          });
        }
      }

      http
        .fetch("/orders/payed", { orders: change }, true)
        .then((data) => {
          this.$emit("newItem", data);
          this.myModal.hide();
          this.$emit("update:show", false);
          this.loadAddBtn = false;
        })
        .catch((data) => {
          let newModelValue = [];
          for (const key in this.modelValue) {
            if (data.errors.indexOf(this.modelValue[key].id) > -1) {
              newModelValue.push(this.modelValue[key]);
            }
          }
          this.$emit("update:modelValue", newModelValue);
          this.loadAddBtn = false;
        });
    },
  },
  components: { BankDetailsToast, EditOnClick },
};
</script>
